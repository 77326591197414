//
// https://angular.io/guide/zone#setting-up-zonejs
// https://github.com/angular/angular/blob/master/packages/zone.js/lib/zone.configurations.api.ts
//
(window as any).__Zone_disable_defineProperty = true;
//(window as any).__Zone_disable_requestAnimationFrame = true; // should be turned off when using ngZoneEventCoalescing or ngZoneRunCoalescing
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove', 'close', 'open', 'mousewheel', 'touchmove'];
(window as any).__Zone_disable_blocking = true;
(window as any).__Zone_disable_MutationObserver = true;
(window as any).__Zone_disable_IntersectionObserver = true;
(window as any).__Zone_disable_XHR = true;
(window as any).__Zone_disable_geolocation = true;
(window as any).__Zone_disable_canvas = true;
(window as any).__zone_symbol__DISABLE_WRAPPING_UNCAUGHT_PROMISE_REJECTION = true;
(window as any).__zone_symbol__ignoreConsoleErrorUncaughtError = true;
